<script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import ReportsHeader from "@/components/reports-header";

export default {
  page: {
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    ReportsHeader,
  },
  data() {
    return {
      trail: [],
      tarilInfo: [],
      accountingPeriods: [],
      type: [],
      totalCredit: 0,
      totalDebit: 0,
      branch_id: null,
      data: {},
    };
  },
  methods: {
    get() {
      this.http
        .post("transactions/trail", { branch_id: this.branch_id })
        .then((res) => {
          this.trail = res.data;
          this.trail.forEach((account) => {
            let value = account.credit - account.debit;

            if (value < 0) {
              this.totalDebit += Math.abs(value);
              account.type = "debit";
              account.value = Math.abs(value);
            } else {
              this.totalCredit += Math.abs(value);
              account.type = "credit";
              account.value = Math.abs(value);
            }
          });

          console.log("..", this.trail);
        });
    },
    searchByDate() {
      console.log(this.data);
      this.totalCredit = 0;
      this.totalDebit = 0;
      this.http
        .post("transactions/trail-report-date", {
          branch_id: this.branch_id,
          from: this.data.from,
          to: this.data.to,
          accounting_period_id: this.data.accounting_period_id,
        })
        .then((resp) => {
          this.trail = resp.data;
          this.trail.forEach((account) => {
            let value = account.credit - account.debit;

            if (value < 0) {
              this.totalDebit += Math.abs(value);
              account.type = "debit";
              account.value = Math.abs(value);
            } else {
              this.totalCredit += Math.abs(value);
              account.type = "credit";
              account.value = Math.abs(value);
            }
          });
        });
    },
    getAccountingPeriods() {
      this.http.get("accounting-peroids").then((resp) => {
        if (resp) {
          this.accountingPeriods = resp.data;
        }
      });
    },
  },

  created() {
    const current_user = JSON.parse(localStorage.currentUser);
    this.branch_id = current_user.branch_id;
    this.getAccountingPeriods();
    this.searchByDate();
  },
};
</script>

<template>
  <Layout>
    <PageHeader
      :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
      :title="$t('menu.menuitems.accounting.subItems.trial-balance')"
    />
    <form @submit.prevent="searchByDate()" class="mb-4">
      <div class="row">
        <div class="col">
          <label class="inline" for="fromJobTitle">{{
            $t("report.period")
          }}</label>
          <select
            v-model="data.accounting_period_id"
            type="date"
            class="form-control"
            id="fromJobTitle"
          >
            <option
              :value="period.id"
              v-for="period in accountingPeriods"
              :key="period"
            >
              {{ period?.start_date + " To " + period?.end_date }}
            </option>
          </select>
        </div>
        <div class="col">
          <label class="inline" for="fromJobTitle">{{
            $t("report.from")
          }}</label>
          <input
            v-model="data.from"
            type="date"
            class="form-control"
            id="fromJobTitle"
          />
        </div>
        <div class="col">
          <label class="inline" for="toJobTitle">{{ $t("report.to") }}</label>
          <input
            v-model="data.to"
            type="date"
            class="form-control fromto"
            id="toJobTitle"
          />
        </div>
        <div class="col-auto" style="top: 16px">
          <button type="submit" class="btn btn-primary">
            {{ $t("popups.search") }}
          </button>
        </div>
        <div class="col"></div>
      </div>
    </form>
    <div class="row">
      <div class="card">
        <div class="card-body">
          <ReportsHeader
            :reportName="$t('reports.trial_balance')"
            class="mb-5"
          />

          <!-- Start search box -->

          <!-- end search box -->

          <!-- <br><br><br> -->
          <div class="table-responsive">
            <table
              :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
              class="table table-centered table-nowrap table-striped table-hover align-middle"
            >
              <thead>
                <tr
                  class="text-light"
                  style="background-color: #2a3042 !important"
                >
                  <!-- <th scope="col">{{ $t("trial_balance.code") }}</th> -->
                  <!-- <th scope="col">
        {{ $t("trial_balance.sub_account/parent_account") }}
      </th> -->
                  <th scope="col">{{ $t("popups.account_no") }}</th>
                  <th scope="col">{{ $t("trial_balance.account") }}</th>
                  <th scope="col">{{ $t("trial_balance.debit") }}</th>
                  <th scope="col">{{ $t("trial_balance.credit") }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="searchIn in trail" :key="searchIn" class="">
                  <!-- <td>{{ searchIn.parentCode }}</td> -->
                  <!-- <td>{{ $i18n.locale == "ar" ? searchIn.name : searchIn.name_en }}</td> -->
                  <td>{{ searchIn.code }}</td>
                  <td>
                    {{
                      $i18n.locale == "ar" ? searchIn.name : searchIn.name_en
                    }}
                  </td>
                  <td>
                    {{
                      searchIn.type == "debit"
                        ? searchIn.value.toLocaleString()
                        : ""
                    }}
                  </td>
                  <td>
                    {{
                      searchIn.type == "credit"
                        ? searchIn.value.toLocaleString()
                        : ""
                    }}
                  </td>
                  <!-- <td v-for="three in searchIn.transaction" :key="three"><span v-for="x in three.transactions" :key="x">{{ x
      }}</span></td> -->
                </tr>
                <tr class="">
                  <th scope="col"></th>
                  <th scope="col"></th>
                  <th scope="col" class="total-style">
                    {{ totalDebit.toLocaleString() }}
                  </th>
                  <th scope="col" class="total-style">
                    {{ totalCredit.toLocaleString() }}
                  </th>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
<style scoped>
.close {
  position: relative;
  right: 317px;
  top: 6px;
  font-size: 15px;
}
</style>
